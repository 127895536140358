<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { QuizStatus } from "@/types";
import { formatRelativeDateDistance } from "@/utils/formatRelativeDateDistance";

import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";

const props = defineProps<{
  id: string;
  title: string;
  supertitle: string;
  date: Date;
  description: string;
  type: QuizStatus;
  alreadySubmitted: boolean;
}>();

const router = useRouter();
const { t } = useI18n();

const isFinished = computed(() => new Date().getTime() > props.date.getTime());

function handleRedirect() {
  if (props.type === QuizStatus.PENDING) {
    router.push({ name: "espace-lab.questionnaire", params: { id: props.id } });
  }
}
</script>

<template>
  <div class="flex flex-col bg-white/10 p-8">
    <p v-if="supertitle" class="mb-4 text-center text-lg/none font-bold">{{ supertitle }}</p>

    <p v-if="date && !isFinished && !alreadySubmitted" class="mb-8 text-center text-sm opacity-50">
      {{ formatRelativeDateDistance(date) }}
    </p>

    <p v-else-if="date && isFinished" class="mb-8 text-center text-sm opacity-50">Terminé</p>

    <h2 class="mb-6 text-[32px] font-bold leading-[38px]">{{ title }}</h2>

    <p class="text-base/5">{{ description }}</p>

    <button
      type="button"
      class="mt-8 flex w-full items-center justify-center rounded border border-transparent bg-white px-6 py-4 text-base text-blue-lbp transition-all duration-200 ease-out hover:border-blue-darker-lbp disabled:bg-opacity-75 disabled:text-blue-darker-lbp disabled:hover:border-transparent"
      :class="{
        hidden: QuizStatus.PENDING && isFinished,
      }"
      :disabled="alreadySubmitted || isFinished"
      @click="handleRedirect"
    >
      <template v-if="type === QuizStatus.PENDING && !alreadySubmitted && !isFinished">
        {{ t("clientLab.quizParticipate") }}
        <ArrowLongRightIcon class="ml-1.5 size-5" />
      </template>

      <template v-else-if="type === QuizStatus.PENDING && alreadySubmitted">
        {{ t("clientLab.quizAlreadySubmitted") }}
      </template>

      <template v-else-if="type === QuizStatus.RESULTS_AVAILABLE">
        {{ t("clientLab.seeQuizResults") }}
      </template>
    </button>
  </div>
</template>
